import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
import PrivateRouter from "./privateRouter";

import {
  NotFound,
  Home,
  Login,
  Repository
} from './../screens';

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>

        {/* Login Route */}
        <Route exact path='/' >
             {/* { <Redirect to="/idm" />} */}

             {sessionStorage.getItem('payload') ? <Redirect to="/idm" /> : <Redirect to="/login" />}
        </Route>
        {/* end */}
        {/* login component */}
        <Route path={Routes.login} component={Login} />
        <PrivateRouter exact path={Routes.idm}>
          <Repository />
        </PrivateRouter>
      
        {/* Home Route */}
        {/* <PrivateRouter exact path={Routes.home}>
          <Home />
        </PrivateRouter> */}

        {/* For unknow/non-defined path */}
        {/* <PrivateRouter exact path="*" component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default RouterApp;
