import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "./routes";
import { Access } from "./access";
import { LocalStorageKeys } from "../utils";

const PrivateRoute = ({ children, ...rest }) => {
  // React.useEffect(()=>{
    
  // })
  const isAuthenticated = () => {
    debugger;
    if (sessionStorage.getItem('payload')) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
    {
       isAuthenticated? 
       <Route
       {...rest}
       render={({ location }) =>
        (
           children
         ) 
       }
     /> 
       : 
       <Route
      //  {...rest}
       render={({ location }) =>
        (
          <Redirect
          to={{
            pathname: Routes.login,
            state: { from: location }
          }}
        />
         ) 
       }
     /> 
    }
    </>

 
  );
}


export default PrivateRoute;
