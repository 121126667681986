import dotenv from "dotenv";
dotenv.config();

let config = {};

config.api_url = `${process.env.REACT_APP_ARANGO_API}`;
config.graphql_url = `${process.env.REACT_APP_GRAPHQL_PROTOCAL}${process.env.REACT_APP_GRAPHQL_HOST}${process.env.REACT_APP_GRAPHQL_PORT}${process.env.REACT_APP_GRAPHQL_BASE_URL}`;
config.graphql_web_socket_url = `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`;
config.keylock_url = `${process.env.REACT_APP_KEY_CLOCK_API}`;
config.qdm_dbname = `${process.env.REACT_APP_QDM_DATABASENAME}`;
config.metadataId = `${process.env.REACT_APP_METADATAID}`;


config.key_cloak_api = process.env.REACT_APP_KEY_CLOAK_API;
config.key_cloak_db =  process.env.REACT_APP_KEY_CLOAK_DB;
config.key_cloak_secret_key = process.env.REACT_APP_KEY_CLOAK_SECRET_KEY;
config.key_cloak_client_id = process.env.REACT_APP_KEY_CLOAK_CLIENT_ID;
export { config };
